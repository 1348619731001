import { createTheme } from '@mui/material/styles'


const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
          noSsr: true,
      },
    }
  },
  typography: {
    fontFamily: [
      'neue-haas-unica, sans-serif',
      '"Helvetica Neue"',
      'Roboto, sans-serif', 
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial'
    ].join(','),
    h1: {
      fontWeight: 900,
      fontSize: '2.8rem',
      letterSpacing: '-0.03rem'
    },
    h2: {
      fontWeight: 900,
      fontSize: '2.4rem',
      letterSpacing: '-0.03rem'
    },
    h3: {
      fontWeight: 900,
      fontSize: '2.0rem',
      letterSpacing: '-0.03rem'
    },
    h4: {
      fontWeight: 900,
      fontSize: '1.6rem',
      letterSpacing: '-0.03rem'
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.4rem',
      letterSpacing: '-0.03rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '0.92rem',
      letterSpacing: '-0.03rem',
      lineHeight: 1,
    },
    subtitle2: {
      fontSize: '0.8rem',
      color: '#474A51'
    },
    body1: {
      fontWeight: 400,
      fontSize: '0.9rem',
      letterSpacing: '-0.03rem',
      lineHeight: 1.2
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.84rem',
      letterSpacing: '-0.03rem',
      lineHeight: 1.2
    },
    caption: {
      fontSize: '0.8rem',
      letterSpacing: '-0.03rem',
      lineHeight: 1.2
    }
  },
  palette: {
    primary: {
      main: '#000000',
      grey: 'rgba(0, 0, 0, 0.54)',
      _900: '#000000',
      _800: '#262626',
      _700: '#434343',
      _600: '#555555',
      _500: '#7b7b7b',
      _400: '#9d9d9d',
      _300: '#c4c4c4',
      _200: '#d9d9d9',
      _100: '#e9e9e9',
       _50: '#f5f5f5'
    }
  }
})

export default theme

