import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Typography from '@mui/material/Typography'



const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 960px;
    margin-top: 50px;
    display: flex;
    flex-wrap: nowrap;
  `,
  main: css`
    width: 720px;
  `,
  news: css`
    width: 180px;
    margin: 40px 0 0 60px;
  `,
  title: css`
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #222;
    margin: 4px 0 0 0;
    margin-bottom: 36px;
  `,
  header: css`
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.1em;
    display: block;
    margin: 1em 0;
    padding-top: 1em;
  `,
  text: css`
    margin: 1em 0;
    font-size: 18px;
    line-height: 24px;
    & > a {
      text-decoration: none;
      color: #4d98ff;
    }
  `,
  list: css`
    & > li {
      margin: 1em 0;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    },
    & > li > a {
      text-decoration: none;
      color: #4d98ff;
    }
  `,
  newsTitle: css`
    font-size: 13px;
    line-height: 17px;
    color: #888;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
  `,
  newsItem: css`
    margin-bottom: 10px;
  `,
  newsItemDate: css`
    text-transform: uppercase;
    font-size: 10px;
    line-height: 15px;
    color: #888;
    letter-spacing: 0.1em;
  `,
  newsItemTitle: css`
    font-size: 14px;
    line-height: 19px;
    color: #888;
    margin: 0;
    padding: 0;
  `
})



const data = [
  {
    id: 1,
    date: '20 Jan 2023',
    title: 'Announcing the Editors of Volumne 133'
  },
  {
    id: 2,
    date: '20 Jan 2023',
    title: 'Announcing the Editors of Volumne 133'
  },
  {
    id: 3,
    date: '20 Jan 2023',
    title: 'Announcing the Editors of Volumne 133'
  },
]




const AboutPage = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <div css={ styles.contentContainer }>
        <div css={ styles.main }>
          <Typography variant='h1' css={ styles.title }>
            About the The Zicklin Review
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            MISSION
          </Typography>

          <Typography css={ styles.text }>
            The mission of the Zicklin Center is to sponsor and disseminate leading-edge 
            research on critical topics in business ethics. It provides students, educators, 
            business leaders, and policymakers with research to meet the ethical, governance, 
            and compliance challenges that arise in complex business transactions.
          </Typography>

          <Typography css={ styles.text }>
            The <em>Center</em> strives to have an impact with its research, convening power, and advocacy.
          </Typography>


          <Typography variant='h1' css={ styles.header }>
            COMPOSITION AND ADMISSION
          </Typography>

          <Typography css={ styles.text }>
          </Typography>


          <Typography variant='h1' css={ styles.header }>
            EDITING PROCESS
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            HISTORY
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            MAJOR ARTICLES
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            THE JOURNAL ONLINE
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            PRODUCTION
          </Typography>
        </div>

        <div css={ styles.news }>
          <Typography css={ styles.newsTitle }>
            NEWS
          </Typography>

          { data.map(news => (
              <div key={ news.id } css={ styles.newsItem }>
                <Typography css={ styles.newsItemDate }>
                  { news.date }
                </Typography>

                <Typography css={ styles.newsItemTitle }>
                  { news.title }
                </Typography>
              </div>
          ))}
        </div>
      </div>
    </div>
  )
}


export default AboutPage


