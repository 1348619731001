import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'



import { data } from './PrintArchivePage'


const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 960px;
    margin-top: 50px;
  `,
  title: css`
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #222;
    margin: 4px 0 0 0;
    margin-bottom: 36px;
  `,
  imageContainer: css`
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  `
})








const MastheadDetailPage = props => {

  const styles = cssStyles()

  const { id } = useParams()


  return (
    <div css={ styles.root }>
      <div css={ styles.contentContainer }>
        <Typography variant='h1' css={ styles.title }>
          Volume { id } Masthead
        </Typography>

        <div css={ styles.imageContainer }>
          <Typography>IMAGE</Typography>
        </div>
      </div>
    </div>
  )
}


export default MastheadDetailPage
