import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionSummary, AccordionDetails } from './CustomAccordion'



const cssStyles = props => ({
  accordionHeader: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  accordinHeaderLeft: css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #888;
  `,
  volume: css`
    width: 150px;
    margin-right: 15px;
    & > strong {
      font-size: 18px;
      color: #222;
    }
  `
})




const CustomAccordionNumberDetails = props => {

  const styles = cssStyles()

  const { number, details } = props


  return (
    <Accordion key={ number.number }>
      <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
        <div css={ styles.accordionHeader }>
          <div css={ styles.accordinHeaderLeft }>
            <Typography css={ styles.volume }>
              NUMBER &nbsp;<strong>{ number.number }</strong>
            </Typography>

            <Typography css={ styles.numberDate }>
              { number.date }
            </Typography>


            <Typography>{ number.version }</Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        { details }
      </AccordionDetails>
    </Accordion>
  )
}



export default CustomAccordionNumberDetails

