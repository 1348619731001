import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import CustomAccordion from './accordion/CustomAccordion'
import CustomAccordionNumberDetails from './accordion/CustomAccordionNumberDetails'
import CustomAccordionArticleDetails from './accordion/CustomAccordionArticleDetails'



import { data } from './PrintArchivePage'


const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 960px;
    margin-top: 50px;
  `,
  title: css`
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #222;
    margin: 4px 0 0 0;
    margin-bottom: 36px;
  `,
  link: css`
    color: #fff;
    text-decoration: none;
  `
})






const MastheadPage = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <div css={ styles.contentContainer }>
        <Typography variant='h1' css={ styles.title }>
          Masthead
        </Typography>

        { data.map(issue => (
            <Link key={ issue.id } css={ styles.link } to={ `/masthead/${issue.volume}` }>
              <CustomAccordion expanded={ false } issue={ issue } />
            </Link>
        ))}
      </div>
    </div>
  )
}


export default MastheadPage


