import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'


const cssStyles = props => ({
  root: css`
    width: 100%;
    height: 200px;
    background-color: #042D7E;
    display: flex;
    flex-direction: column;
  `,
  header: css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  `,
  headerContent: css`
    width: 960px;
  `,
  imageContainer: css`
    width: 70px;
    margin-bottom: 15px;
  `,
  image: css`
    width: 100%;
    object-fit: cover;
  `,
  headerTitle: css`
    color: #fff;
    font-size: 36px;
    margin-bottom: 20px;
  `,
  navigationWrapper: css`
    width: 100%;
    height: 40px;
    background-color: #000f5c;
    display: flex;
    justify-content: center;
  `,
  navigation: css`
    height: 100%;
    width: 960px;
  `,
  list: css`
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    height: 100%;
    margin: 0;
    color: #fff;
    & > li {
      padding-right: 44px;
      cursor: pointer;
    },
  `,
  link: css`
    color: #fff;
    text-decoration: none;
  `,
  menuItem: css`
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.1em;
    height: 40px;
    color: #555555;
    padding: 0;
    border-bottom: solid 1px #999;
    text-transform: uppercase;
    text-align: left;
    & > a {
      color: #555555;
      display: inline-block;
      text-decoration: none;
      width: 100%;
    }
  `,
})


const src = 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/UPenn_shield_with_banner.svg/1200px-UPenn_shield_with_banner.svg.png'


const Header = props => {

  const styles = cssStyles()

  const [anchorEl, setAnchorEl] = useState(null)


  function handleClick(e) {
    setAnchorEl(e.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }


  return (
    <div css={ styles.root }>
      <div css={ styles.header }>
        <div css={ styles.headerContent }>
          <div css={ styles.imageContainer }>
            <img css={ styles.image } src={ src } />
          </div>

          <Typography css={ styles.headerTitle }>
            <Link css={ styles.link} to={ '/' }>
              The Zicklin Review
            </Link>
          </Typography>
        </div>
      </div>

      
      <div css={ styles.navigationWrapper }>
        <nav css={ styles.navigation }>
          <ul css={ styles.list }>
            <li>
              <Link css={ styles.link} to={ '/issue' }>
                PRINT ARCHIVE
              </Link>
            </li>
            <li>
              <Link css={ styles.link} to={ '/forum' }>
                FORUM
              </Link>
            </li>
            <li>
              <Link css={ styles.link} to={ '/submissions' }>
                SUBMISSIONS
              </Link>
            </li>
            <li>
              <Link css={ styles.link} to={ '/masthead' }>
                MASTHEAD
              </Link>
            </li>
            <li>
              <Link css={ styles.link} to={ '/about' }>
                ABOUT
              </Link>
            </li>
            <li>
              <div onMouseOver={ handleClick }>CONTACT</div>
              <Menu 
                anchorEl={ anchorEl } 
                open={ Boolean(anchorEl) }
                onClose={ handleClose }
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                MenuListProps={{ onMouseLeave: handleClose }}
                PaperProps={{
                  style: {
                    marginTop: 12,
                    borderRadius: 0,
                    width: 180,
                    padding: 10
                  }
                }}
              >
                <MenuItem css={ styles.menuItem }>
                  <Link to={ '/contact' }>
                    Contact Us
                  </Link>
                </MenuItem>
                <MenuItem css={ styles.menuItem }>Alumni</MenuItem>
                <MenuItem css={ styles.menuItem }>Subscriptions</MenuItem>
              </Menu>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}


export default Header


