import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'

import Typography from '@mui/material/Typography'



const cssStyles = props => ({
  article: css`
    width: 600px;
    margin: 0 0 20px 0;
  `,
  articleCategory: css`
    font-size: 10px;
    font-weight: normal;
    color: #888;
    text-transform: uppercase;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    letter-spacing: 0.10em;
  `,
  articleTitle: css`
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    color: #4d98ff;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  `,
  articleAuthor: css`
    font-size: 14px;
    line-height: 19px;
    color: #888;
  `
})




const CustomAccordionNumberDetails = props => {

  const styles = cssStyles()
  
  const { article } = props


  return (
    <div key={ article.id } css={ styles.article }>
      <Typography css={ styles.articleCategory }>
        { article.category }
      </Typography>

      <Typography css={ styles.articleTitle }>
        { article.title }
      </Typography>

      <Typography css={ styles.articleAuthor }>
        { article.author }
      </Typography>
    </div>
  )
}



export default CustomAccordionNumberDetails