import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import CustomAccordion from './accordion/CustomAccordion'
import CustomAccordionNumberDetails from './accordion/CustomAccordionNumberDetails'
import CustomAccordionArticleDetails from './accordion/CustomAccordionArticleDetails'


const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 960px;
    margin-top: 50px;
  `,
  title: css`
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #222;
    margin: 4px 0 0 0;
    margin-bottom: 36px;
  `,
  articlesContainer: css`
    margin: 25px 60px;
  `,
})


export const data = [
  {
    id: 1,
    volume: 132,
    years: '2022-2023',
    numbers: [
      {
        number: 1,
        date: 'JUNE 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      },
      {
        number: 2,
        date: 'MAY 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      },
      {
        number: 3,
        date: 'APRIL 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      }
    ]
  },
  {
    id: 2,
    volume: 131,
    years: '2022-2023',
    numbers: [
      {
        number: 1,
        date: 'JUNE 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      },
      {
        number: 2,
        date: 'MAY 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      },
      {
        number: 3,
        date: 'APRIL 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      }
    ]
  },
  {
    id: 3,
    volume: 130,
    years: '2022-2023',
    numbers: [
      {
        number: 1,
        date: 'JUNE 2022',
        version: '2390-2758',
        articles: []
      },
      {
        number: 2,
        date: 'MAY 2022',
        version: '2390-2758',
        articles: []
      },
      {
        number: 3,
        date: 'APRIL 2022',
        version: '2390-2758',
        articles: []
      }
    ]
  },
  {
    id: 4,
    volume: 129,
    years: '2022-2023',
    numbers: [
      {
        number: 1,
        date: 'JUNE 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      },
      {
        number: 2,
        date: 'MAY 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      },
      {
        number: 3,
        date: 'APRIL 2022',
        version: '2390-2758',
        articles: [
          {
            id: 1,
            category: 'article',
            title: 'Interconstitutionalism',
            author: 'Jason Mazzone & Cem Tecimer'
          },
          {
            id: 2,
            category: 'article',
            title: 'Barbarians Inside the Gates: Raiders, Activists, and the Risk of Mistargeting',
            author: 'Zohar Goshen & Reilly S. Steel'
          },
          {
            id: 3,
            category: 'review',
            title: 'What We Ask of Law',
            author: 'Aziz Z. Huq'
          },
          {
            id: 4,
            category: 'note',
            title: 'The Neglected Port Preference Clause and the Jones Act',
            author: 'Sam Heavenrich'
          },
        ]
      }
    ]
  }
]







const PrintArchivePage = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <div css={ styles.contentContainer }>
        <Typography variant='h1' css={ styles.title }>
          Print Archive
        </Typography>

        { data.map(issue => (
            <CustomAccordion 
              key={ issue.id }
              issue={ issue }
              details={ 
                <div>
                  { issue.numbers.map(number => (
                    <CustomAccordionNumberDetails 
                      key={ number.number }
                      number={ number } 
                      details={ 
                        <div css={ styles.articlesContainer }>
                          { number.articles.map(article => (
                              <CustomAccordionArticleDetails 
                                key={ article.id }
                                article={ article } 
                              />
                          ))}
                        </div>
                      }
                    />
                  ))}
                </div>
              }
            />
        ))}
      </div>
    </div>
  )
}


export default PrintArchivePage

