import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Typography from '@mui/material/Typography'



const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  sectionTitle: css`
    font-size: 16px;
    text-align: center;
    margin: 1em 0;
    width: 100%;
  `,
  content: css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,
  item: css`
    width: 440px;
    height: 180px;
    background-color: #f8f8f8;
    padding: 0 10px;
    margin: 0 0 40px;
  `,
  line: css`
    border-top: 3px solid #bbb;
    margin: 0 0;
    width: 300px;
  `,
  type: css`
    margin: 15px 0 0;
    text-transform: uppercase;
    font-size: 13px;
  `,
  title: css`
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    text-transform: none;
    margin: 0 0;
    color: #002a80;
  `,
  author: css`
    color: #444;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 25px;
  `,
  text: css`
    font-size: 16px;
    line-height: 21.33px;
    color: #444;
    margin: 0 0; 
  `,
  footer: css`
    margin: 15px 0 0;
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #444;
  `
})



const data = [
  {
    id: 1,
    type: 'collection',
    title: 'Announcing the Editors of Volume 133',
    author: 'Zohar Goshen & Reilly S. Steel',
    text: 'This Article argues that the conventional wisdom about corporate raiders and activist hedge funds—lambasting raiders and praising activists—is wrong. The authors explain how activists are more likely than raiders to engage in mistargeting, implying they are also more likely to destroy value and, ultimately, social wealth.',
    date: '30 Nov 2022',
    topic: 'corporate law'
  },
  {
    id: 2,
    type: 'collection',
    title: 'Announcing the Editors of Volume 133',
    author: 'Zohar Goshen & Reilly S. Steel',
    text: 'This Article argues that the conventional wisdom about corporate raiders and activist hedge funds—lambasting raiders and praising activists—is wrong. The authors explain how activists are more likely than raiders to engage in mistargeting, implying they are also more likely to destroy value and, ultimately, social wealth.',
    date: '30 Nov 2022',
    topic: 'corporate law'
  },
  {
    id: 3,
    type: 'collection',
    title: 'Announcing the Editors of Volume 133',
    author: 'Zohar Goshen & Reilly S. Steel',
    text: 'This Article argues that the conventional wisdom about corporate raiders and activist hedge funds—lambasting raiders and praising activists—is wrong. The authors explain how activists are more likely than raiders to engage in mistargeting, implying they are also more likely to destroy value and, ultimately, social wealth.',
    date: '30 Nov 2022',
    topic: 'corporate law'
  },
  {
    id: 4,
    type: 'collection',
    title: 'Announcing the Editors of Volume 133',
    author: 'Zohar Goshen & Reilly S. Steel',
    text: 'This Article argues that the conventional wisdom about corporate raiders and activist hedge funds—lambasting raiders and praising activists—is wrong. The authors explain how activists are more likely than raiders to engage in mistargeting, implying they are also more likely to destroy value and, ultimately, social wealth.',
    date: '30 Nov 2022',
    topic: 'corporate law'
  }
]



const HomePageNewsSection = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <Typography css={ styles.sectionTitle } variant='h3'>
        NEWS
      </Typography>

      <div css={ styles.content }>
        { data.map(article => (
            <div key={ article.id } css={ styles.item }>
              <hr css={ styles.line } />

              <Typography css={ styles.footer }>
                { article.date }
              </Typography>
            

              <Typography variant='h3' css={ styles.title }>
                { article.title }
              </Typography>

            </div>
        ))}
      </div>
    </div>
  )
}


export default HomePageNewsSection
