import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Typography from '@mui/material/Typography'
import CustomAccordion from './accordion/CustomAccordion'
import CustomAccordionForumDetails from './accordion/CustomAccordionForumDetails'



const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 960px;
    margin-top: 50px;
  `,
  title: css`
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #222;
    margin: 4px 0 0 0;
    margin-bottom: 36px;
  `,
  link: css`
    color: #fff;
    text-decoration: none;
  `,
  forumDetailsContainer: css`
    margin: 25px 60px;
  `
})



const data = [
  {
    id: 1,
    volume: 132,
    category: 'collection',
    collection: [
      {
        id: 1,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      },
      {
        id: 2,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      },
      {
        id: 3,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      },
      {
        id: 4,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      },
    ]
  },
  {
    id: 2,
    volume: 131,
    category: 'collection',
    collection: [
      {
        id: 1,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      },
      {
        id: 2,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      },
      {
        id: 3,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      },
      {
        id: 4,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      },
    ]
  },
  {
    id: 3,
    volume: 130,
    category: 'article',
    collection: [
      {
        id: 1,
        title: 'Lock Them™ Up: Holding Transnational Corporate Human-Rights Abusers Accountable',
        text: 'In the increasingly globalized modern economy, large corporate actors have long operated with relative impunity for transnational human-rights abuses committed in the name of profit maximization. This Collection explores perspectives from a range of voices engaged in the fight for corporate accountability in both the United States and abroad.',
        date: '22 Dec 2022'
      }
    ]
  }
]



const ForumPage = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <div css={ styles.contentContainer }>
        <Typography variant='h1' css={ styles.title }>
          Forum
        </Typography>

        { data.map(issue => (
            <CustomAccordion
              key={ issue.id } 
              issue={ issue }
              details={ 
                <div css={ styles.forumDetailsContainer }>
                  <CustomAccordionForumDetails collection={ issue.collection } />
                </div>
              }
            />
        ))}
      </div>
    </div>
  )
}


export default ForumPage

