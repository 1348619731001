import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Typography from '@mui/material/Typography'



const cssStyles = props => ({
  root: css`
    width: 100%;
    overflow: hidden;
  `,
  header: css`
    font-size: 10px;
    line-height: 15px;
    font-weight: normal;
    color: #888;
    text-transform: uppercase;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    letter-spacing: 0.10em;
  `,
  contentContainer: css`
    width: 960px;
    margin-top: 50px;
  `,
  collectionItem: css`
    font-size: 14px;
    line-height: 19px;
    color: #888;
    margin: 0 0 20px 0;
    padding: 0 0 0 0;
  `,
  collectionItemTitle: css`
    font-size: 21px;
    line-height: 27px;
    color: #4d98ff;
    font-weight: normal;
    margin: 0;
    padding: 0;
  `
})




const CustomAccordionForumDetails = props => {

  const styles = cssStyles()

  const { collection } = props

  return (
    <div css={ styles.root }>
      <div css={ styles.collectionContainer }>
        <Typography css={ styles.header }>FORUM COLLECTION</Typography>

        { collection.map(article => (
            <div key={ article.id } css={ styles.collectionItem }>
              <Typography css={ styles.collectionItemTitle }>
                { article.title }
              </Typography>

              <Typography css={ styles.collectionItemTitle }>
                { article.author }
              </Typography>

              <Typography>
                { article.text }
              </Typography>
            </div>
        ))}
      </div>
    </div>
  )
}


export default CustomAccordionForumDetails