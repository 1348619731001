import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'



const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 960px;
    margin-top: 50px;
  `,
  title: css`
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #222;
    margin: 4px 0 0 0;
    margin-bottom: 36px;
  `,
  text: css`
    font-size: 18px;
    line-height: 24px;
    & > a {
      text-decoration: none;
      color: #4d98ff;
    }
  `,
  link: css`
    color: #fff;
    text-decoration: none;
  `
})




const ContactPage = props => {

  const styles = cssStyles()

  return (
    <div css={ styles.root }>
      <div css={ styles.contentContainer }>
        <Typography variant='h1' css={ styles.title }>
          Contact
        </Typography>

        <Typography css={ styles.text }>
          To submit work for publication, please use our online submission system 
          &nbsp;<Link to='/submissions'>here</Link>.
        </Typography>

        <br/>

        <Typography css={ styles.text }>
          Mailing Address
        </Typography>

        <br/>

        <Typography css={ styles.text }>
          The Zicklin Review<br/>
          University of Pennsylvania<br/>
          3733 Spruce Street<br/>
          Philadelphia, PA 19104<br/>
          Phone (215) 898-2575
        </Typography>
      </div>
    </div>
  )
}



export default ContactPage