import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'


const cssStyles = props => ({
  accordionHeader: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  accordinHeaderLeft: css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #888;
  `,
  volume: css`
    width: 150px;
    margin-right: 15px;
    & > strong {
      font-size: 18px;
      color: #222;
    }
  `
})


export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={ 0 } square { ...props } />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={ <ChevronRightIcon sx={{ fontSize: '0.9rem' }} /> }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))



const CustomAccordion = props => {

  const styles = cssStyles()

  const { issue, details, ...rest } = props


  return (
    <Accordion key={ issue.id } { ...rest }>
      <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
        <div css={ styles.accordionHeader }>
          <div css={ styles.accordinHeaderLeft }>
            <Typography css={ styles.volume }>
              VOLUME &nbsp;<strong>{ issue.volume }</strong>
            </Typography>

            <Typography>{ issue.years }</Typography>
          </div>

          <Typography>
            VIEW MASTHEAD
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        { details }
      </AccordionDetails>
    </Accordion>
  )
}



export default CustomAccordion

