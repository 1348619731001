
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomePage from './components/home/HomePage'
import SubmissionsPage from './components/SubmissionsPage'
import AboutPage from './components/AboutPage'
import PrintArchivePage from './components/PrintArchivePage'
import MastheadPage from './components/MastheadPage'
import MastheadDetailPage from './components/MastheadDetailPage'
import ForumPage from './components/ForumPage'
import ContactPage from './components/ContactPage'
import './App.css'



const App = () => {
  return (
    <>
      <Header />


     <Routes>
      <Route path='/' exact element={ <HomePage /> } />
      <Route path='/submissions' exact element={ <SubmissionsPage /> } />
      <Route path='/about' exact element={ <AboutPage /> } />
      <Route path='/issue' exact element={ <PrintArchivePage /> } />
      <Route path='/masthead' exact element={ <MastheadPage /> } />
      <Route path='/masthead/:id' exact element={ <MastheadDetailPage /> } />
      <Route path='/forum' exact element={ <ForumPage /> } />
      <Route path='/contact' exact element={ <ContactPage /> } />
     </Routes>

     <Footer />
    </>
  )
}

export default App
