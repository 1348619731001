import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'


const cssStyles = props => ({
  root: css`
    width: 960px;
    font-size: 12px;
    line-height: 16px;
    color: #4d98ff;
    border-top: 1px solid #4d98ff;
    margin: 100px auto 60px;
    padding: 10px 0 0;
  `,
  header: css`
    flex-grow: 1;
  `,
  container: css`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  `,
  navigation: css`
    width: 100%;
    height: 100%;
  `,
  list: css`
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 650px;
    display: flex;
    align-items: center;
    color: #fff;
    & > li {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-right: 25px;
      font-size: 12px;
      line-height: 16px;
      color: #4d98ff;
    }
  `,
})



const Footer = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <div css={ styles.header }>


      </div>

      
      <div css={ styles.container }>
        <ul css={ styles.list }>
          <li>SUBSCRIPTION</li>
          <li>CONTACT</li>
          <li>TERMS OF USE</li>
          <li>PRIVACY STATEMENT</li>
          <li>MEMBER LOGIN</li>
        </ul>

        <small>
          Copyright © 2023 The Zicklin Review. All rights reserved.
        </small>
      </div>
    </div>
  )
}


export default Footer