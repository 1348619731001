import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Swiper, SwiperSlide } from 'swiper/react'

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'


import '../../styles/swiper.css'


const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 100%;
    max-width: 960px;
  `,
  header: css`
    flex-grow: 1;
  `,
})






const HomePageSwiper = props => {

  const styles = cssStyles()


  return (
    <Swiper
      cssMode={ true }
      navigation={ true }
      pagination={ true }
      mousewheel={ true }
      keyboard={ true }
      modules={ [Navigation, Pagination, Mousewheel, Keyboard] }
      className="mySwiper"
    >
      <SwiperSlide>Slide 1</SwiperSlide>
      <SwiperSlide>Slide 2</SwiperSlide>
      <SwiperSlide>Slide 3</SwiperSlide>
      <SwiperSlide>Slide 4</SwiperSlide>
      <SwiperSlide>Slide 5</SwiperSlide>
    </Swiper>
  )
}


export default HomePageSwiper