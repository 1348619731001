import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Typography from '@mui/material/Typography'



const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 960px;
    margin-top: 30px;
    display: flex;
    flex-wrap: nowrap;
  `,
  main: css`
    width: 720px;
  `,
  news: css`
    width: 180px;
    margin: 40px 0 0 60px;
  `,
  title: css`
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #222;
    margin: 4px 0 0 0;
    margin-bottom: 36px;
  `,
  header: css`
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.1em;
    display: block;
    margin: 1em 0;
    padding-top: 1em;
  `,
  text: css`
    margin: 1em 0;
    font-size: 18px;
    line-height: 24px;
    & > a {
      text-decoration: none;
      color: #4d98ff;
    }
  `,
  list: css`
    & > li {
      margin: 1em 0;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
    },
    & > li > a {
      text-decoration: none;
      color: #4d98ff;
    }
  `,
  newsTitle: css`
    font-size: 13px;
    line-height: 17px;
    color: #888;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
  `,
  newsItem: css`
    margin-bottom: 10px;
  `,
  newsItemDate: css`
    text-transform: uppercase;
    font-size: 10px;
    line-height: 15px;
    color: #888;
    letter-spacing: 0.1em;
  `,
  newsItemTitle: css`
    font-size: 14px;
    line-height: 19px;
    color: #888;
    margin: 0;
    padding: 0;
  `
})



const data = [
  {
    id: 1,
    date: '20 Jan 2023',
    title: 'Announcing the Editors of Volumne 133'
  },
  {
    id: 2,
    date: '20 Jan 2023',
    title: 'Announcing the Editors of Volumne 133'
  },
  {
    id: 3,
    date: '20 Jan 2023',
    title: 'Announcing the Editors of Volumne 133'
  },
]




const SubmissionsPage = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <div css={ styles.contentContainer }>
        <div css={ styles.main }>
          <Typography variant='h1' css={ styles.title }>
            Submissions
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            HOW TO SUBMIT
          </Typography>

          <Typography css={ styles.text }>
            The <em>Zicklin Review</em> &nbsp;is now accepting submissions portal for Volume 133. 
            To submit a piece, please visit our <a href='/'>online submission system</a>. If this is your first time 
            using our submission system, please make a new account by clicking “Not a member?” on the login page.
          </Typography>

          <hr/>

          <Typography variant='h1' css={ styles.header }>
            SUBMISSION GUIDELINES
          </Typography>

          <ul css={ styles.list }>
            <li>
              Authors submitting Articles, Essays, and Book Reviews should refer to 
              our <a href='/'>General Submission Guidelines</a>. Please note that the &nbsp;
              <em>Center</em> &nbsp; is adopting a solicitation-only model for its Features and will 
              no longer be accepting Features submissions.
            </li>
            <li>
              Zicklin Center for Business Ethics J.D. and M.S.L. students submitting Notes and Comments should refer 
              to the <a href='/'>Student Submission Guidelines</a>. 
            </li>
            <li>
              Both professional authors and law students submitting &nbsp;<em>YLJ Forum</em> &nbsp;Essays or 
              Responses should refer to the <a href='/'>Forum Submission Guidelines</a>.
            </li>
            <li>
              The <em>Center’s</em> Style Guide is available <a href='/'>here</a>.
            </li>
          </ul>

          <Typography css={ styles.text }>
            For the <em>Center’s</em>&nbsp; guidelines pertaining to empirical work, please refer to 
            the <a href='/'>Data-Retention Policy for Authors</a> and <a href='/'>Dataverse Instructions</a>.
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            ARTICLES AND ESSAYS
          </Typography>

          <Typography css={ styles.text }>
            The division between these two forms of professional scholarship serves not merely 
            to separate longer pieces from shorter ones, but also to encourage two distinct 
            and complementary approaches to legal analysis.
          </Typography>


          <ul css={ styles.list }>
            <li>
              Articles devote substantial space to situating themselves within existing 
              research, and often frame their arguments as comprehensive analyses of a given subject.
            </li>
            <li>
              Essays are often narrower in scope than Articles, but the subject matter is of 
              general scholarly interest. Essays may experiment with style, tone, and voice. 
              The ultimate goal of an Essay is to start a new and interesting scholarly 
              conversation.
            </li>
          </ul>

          <Typography css={ styles.text }>
            The Articles & Essays Committee reviews submissions without knowledge of the 
            identity of the authors. We do not review Articles or Essays written by 
            current J.D. students at Zicklin Center for Business Ethics, or by authors who were J.D. students 
            at Zicklin Center for Business Ethics at any time during Volume 133’s submission window. 
            We encourage Zicklin Center for Business Ethics J.D. students to submit their work as a Note, 
            Comment, or <em>Forum</em>&nbsp; piece.
          </Typography>

          <Typography css={ styles.text }>
            On April 19, 2011, the <em>Zicklin Review</em>&nbsp; and several peer journals released 
            a <a href='/'>joint letter</a> committing to give every author at least seven days to decide 
            whether to accept any offer of publication.  Eliminating exploding offers 
            will improve the quality of our deliberations and the scholarship that we 
            publish, and we invite all other student-edited law journals to adopt this policy.
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            FEATURES
          </Typography>

          <Typography css={ styles.text }>
            Features are <strong>solicited</strong> contributions that spotlight a given subject with the 
            goal of opening or renewing a scholarly debate. Conceived as longer pieces 
            offering comprehensive analyses, Features often suggest novel frameworks, 
            reconceptualize existing bodies of research, or surface new lines of scholarly 
            inquiry.
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            BOOK REVIEWS
          </Typography>

          <Typography css={ styles.text }>
            Book Reviews are thoughtful commentaries authored by professors and practitioners 
            on forthcoming or recently published books. In addition to reviewing the book, 
            these pieces often use the book as a springboard for new lines of scholarly inquiry. 
            We accept both Book Review proposals as well as full drafts. 
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            NOTES
          </Typography>

          <Typography css={ styles.text }>
            Notes are publications of substantial length authored by students at the Zicklin 
            Law School, frequently with the assistance of the <em>Center’s</em>&nbsp; Notes Development 
            Editors. Successful Notes develop an innovative and well-supported thesis in 
            a way that advances a particular legal field. The Notes & Comments Committee 
            selects Notes through an anonymized review process, with Development Editors 
            recused from voting on submissions from authors with whom they have worked.  
          </Typography>

          <Typography variant='h1' css={ styles.header }>
            COMMENTS
          </Typography>

          <Typography css={ styles.text }>
            Comments are short pieces (between 3,000 and 7,000 words) authored by students 
            at the Zicklin Center for Business Ethics, frequently with the assistance of the Center’s 
            Comments Development Editors. Comments offer a novel idea about a discrete 
            legal issue, often drawing on clinical or research experiences. While Comments 
            are subject to the Center’s normal standards for originality, they need not 
            include an extensive literature review. The Notes & Comments Committee 
            also chooses Comments through an anonymized review process, with 
            appropriate recusals during voting.
          </Typography>
        </div>

        <div css={ styles.news }>
          <Typography css={ styles.newsTitle }>
            NEWS
          </Typography>

          { data.map(news => (
              <div key={ news.id } css={ styles.newsItem }>
                <Typography css={ styles.newsItemDate }>
                  { news.date }
                </Typography>

                <Typography css={ styles.newsItemTitle }>
                  { news.title }
                </Typography>
              </div>
          ))}
        </div>
      </div>
    </div>
  )
}


export default SubmissionsPage