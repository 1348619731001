import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import HomePageSwiper from './HomePageSwiper'
import HomePageRecentSection from './HomePageRecentSection'
import HomePageFeaturedSection from './HomePageFeaturedSection'
import HomePageNewsSection from './HomePageNewsSection'


const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  contentContainer: css`
    width: 960px;
  `,
  swiper: css`
    height: 550px;
    margin-bottom: 50px;
  `,
  recent: css`
    min-height: 480px;
    margin-bottom: 50px;
  `
})




const HomePage = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <div css={ styles.contentContainer }>

        <div css={ styles.swiper }>
          <HomePageSwiper />
        </div>

        <hr/>

        <div css={ styles.recent }>
          <HomePageRecentSection />
        </div>

        <hr/>

        <div>
          <HomePageFeaturedSection />
        </div>

        <hr/>

        <div>
          <HomePageNewsSection />
        </div>

      </div>
    </div>
  )
}


export default HomePage