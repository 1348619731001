import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Typography from '@mui/material/Typography'



const cssStyles = props => ({
  root: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  sectionTitle: css`
    font-size: 16px;
    text-align: center;
    margin: 1em 0;
    width: 100%;
  `,
  content: css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,
  item: css`
    width: 273px;
    min-height: 480px;
    padding: 0 10px 20px;
  `,
  line: css`
    border-top: 3px solid #ddd;
    margin: 0 0px;
    width: 120px;
  `,
  type: css`
    margin: 15px 0 0;
    text-transform: uppercase;
    font-size: 13px;
  `,
  title: css`
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    text-transform: none;
    margin: 0 0;
    color: #002a80;
  `,
  text: css`
    font-size: 16px;
    line-height: 21.33px;
    color: #444;
    margin: 0 0; 
  `,
  footer: css`
    margin: 15px 0 0;
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #444;
  `
})



const data = [
  {
    id: 1,
    type: 'collection',
    title: 'Administrative Law at a Turning Point',
    author: 'Zohar Goshen & Reilly S. Steel',
    text: 'Administrative law faces a critical juncture. Settled doctrines ranging from deference to agency interpretations of statutes to delegations of executive power have been destabilized. And earlier this year, Justice Breyer—himself an administrative-law scholar—retired from the Supreme Court. We publish this Collection as a tribute to his judicial legacy.',
    date: '21 Nov 2022',
    topic: 'administrative law'
  },
  {
    id: 2,
    type: 'collection',
    title: 'Administrative Law at a Turning Point',
    author: 'Zohar Goshen & Reilly S. Steel',
    text: 'Administrative law faces a critical juncture. Settled doctrines ranging from deference to agency interpretations of statutes to delegations of executive power have been destabilized. And earlier this year, Justice Breyer—himself an administrative-law scholar—retired from the Supreme Court. We publish this Collection as a tribute to his judicial legacy.',
    date: '21 Nov 2022',
    topic: 'administrative law'
  },
  {
    id: 3,
    type: 'collection',
    title: 'Administrative Law at a Turning Point',
    author: 'Zohar Goshen & Reilly S. Steel',
    text: 'Administrative law faces a critical juncture. Settled doctrines ranging from deference to agency interpretations of statutes to delegations of executive power have been destabilized. And earlier this year, Justice Breyer—himself an administrative-law scholar—retired from the Supreme Court. We publish this Collection as a tribute to his judicial legacy.',
    date: '21 Nov 2022',
    topic: 'administrative law'
  },
]



const HomePageFeaturedSection = props => {

  const styles = cssStyles()


  return (
    <div css={ styles.root }>
      <Typography css={ styles.sectionTitle } variant='h3'>
        FEATURED CONTENT
      </Typography>

      <div css={ styles.content }>
        { data.map(article => (
            <div key={ article.id } css={ styles.item }>
              <hr css={ styles.line } />
              
              <Typography variant='h6' css={ styles.type }>
                { article.type }
              </Typography>

              <Typography variant='h3' css={ styles.title }>
                { article.title }
              </Typography>


              <Typography variant='p' css={ styles.text }>
                { article.text }
              </Typography>


              <Typography css={ styles.footer }>
                { article.date }
                <br/>
                { article.topic }
              </Typography>
            </div>
        ))}
      </div>
    </div>
  )
}


export default HomePageFeaturedSection

